import React, { Children } from 'react';
import * as PrimitiveDialog from '@radix-ui/react-dialog';
import cx from 'classnames';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import * as style from './Modal.css';
import { IconButton } from '../Button/Button';

export type ModalContentVariantTypes = NonNullable<style.ModalContentVariants>;
export type ModalFooterVariantTypes = NonNullable<style.ModalFooterVariants>;
export type ModalOverlayVariantTypes = NonNullable<style.ModalOverlayVariants>;
export type ModalSizeVariantTypes = keyof style.ModalSizeVariants;

export type ModalProps = PrimitiveDialog.DialogProps;

export type ModalHeaderProps = {
  hideClose?: boolean;
  unsetChildren?: boolean;
  onClose?: () => void;
};

export interface ModalSubTitleProps extends PrimitiveDialog.DialogDescriptionProps {
  children: React.ReactText;
}

export type ModalContainerProps = PrimitiveDialog.DialogPortalProps &
  PrimitiveDialog.DialogContentProps &
  style.ModalOverlayVariants &
  style.ModalContentVariants & {
    fullScreen?: boolean;
    size?: ModalSizeVariantTypes;
  };

export const ModalHeader = ({
  hideClose = false,
  unsetChildren,
  children,
  onClose,
}: React.PropsWithChildren<ModalHeaderProps>) => (
  <div className={style.modalHeader}>
    {unsetChildren ? children : <div>{children}</div>}
    {hideClose ? null : (
      <span className={style.modalCloseButtonContainer}>
        <PrimitiveDialog.Close asChild>
          <IconButton
            variant="modalClose"
            size="small"
            icon={Icons.Close}
            label="close"
            aria-label="close"
            onClick={onClose}
          />
        </PrimitiveDialog.Close>
      </span>
    )}
  </div>
);

export const ModalSubTitle = ({ children, ...props }: ModalSubTitleProps): JSX.Element => (
  <PrimitiveDialog.Description className={style.modalSubTitle} {...props}>
    {children}
  </PrimitiveDialog.Description>
);

export const ModalTitle = ({ children, ...props }: PrimitiveDialog.DialogTitleProps): JSX.Element => (
  <PrimitiveDialog.Title className={style.modalTitle} {...props}>
    {children}
  </PrimitiveDialog.Title>
);

type ModalBodyProps = {
  fullHeight?: boolean;
};

export const ModalBody = React.forwardRef<HTMLElement, React.PropsWithChildren<ModalBodyProps>>((props, ref) => (
  <div
    ref={ref as React.Ref<HTMLDivElement>}
    className={cx(style.modalBody, { [style.modalBodyFullHeight]: !!props.fullHeight })}
  >
    {props.children}
  </div>
));

export const ModalFooter = ({ children }: React.PropsWithChildren<Record<never, any>>): JSX.Element => (
  <div className={style.modalFooter}>{children}</div>
);

export const ModalFooterActions = ({
  direction,
  children,
}: React.PropsWithChildren<style.ModalFooterVariants>): JSX.Element => {
  const resolvedChildren =
    direction === 'right' && children && Children.count(children) >= 2
      ? Children.toArray(children).reverse()
      : children;

  return (
    <div
      className={style.modalActionFooter({
        direction,
      })}
    >
      {resolvedChildren}
    </div>
  );
};

export const ModalClose = React.forwardRef<HTMLButtonElement, PrimitiveDialog.DialogCloseProps>(
  ({ ...props }, forwardedRef): JSX.Element => <PrimitiveDialog.Close {...props} asChild ref={forwardedRef} />,
);

export const ModalTrigger = ({ children, ...props }: PrimitiveDialog.DialogTriggerProps): JSX.Element => (
  <PrimitiveDialog.Trigger {...props} asChild>
    {children}
  </PrimitiveDialog.Trigger>
);

export const ModalContainer = ({
  elevated,
  container,
  overlayVariant,
  children,
  fullScreen,
  size = 'medium',
  ...props
}: ModalContainerProps) => {
  const overlayClass = (Boolean(container) && 'contained') || 'standard';
  const contentClass = (Boolean(fullScreen) && 'fullScreen') || overlayClass;
  const { forceMount, ...contentProps } = props;

  return (
    <PrimitiveDialog.Portal container={container} forceMount={forceMount}>
      <PrimitiveDialog.Overlay
        className={style.modalOverlay({
          positioning: overlayClass,
          overlayVariant,
          fullScreen,
        })}
        data-testid="overlay-test"
      />
      ;
      <PrimitiveDialog.Content
        className={cx(style.modalContent({ elevated, positioning: contentClass }), {
          [`${style.modalSizes[size]}`]: !fullScreen,
        })}
        {...contentProps}
      >
        {children}
      </PrimitiveDialog.Content>
    </PrimitiveDialog.Portal>
  );
};

const ModalRoot = ({ children, ...props }: ModalProps): JSX.Element => (
  <PrimitiveDialog.Root {...props}>{children}</PrimitiveDialog.Root>
);

export const Modal = Object.assign(ModalRoot, {
  Root: ModalRoot,
  Header: ModalHeader,
  Title: ModalTitle,
  SubTitle: ModalSubTitle,
  Body: ModalBody,
  Footer: ModalFooter,
  FooterActions: ModalFooterActions,
  Close: ModalClose,
  Trigger: ModalTrigger,
  Container: ModalContainer,
});
