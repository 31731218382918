import React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import cx from 'classnames';
import { IconOptions } from '@mutiny-pkg/dumpster-ui/utils/sharedTypes';
import * as style from './Link.css';
import * as buttonStyle from '../Button/Button.css';

type AsButtonPropsType = {
  asButton?: boolean;
  buttonVariant?: NonNullable<buttonStyle.ButtonVariants>['variant'];
  buttonSize?: NonNullable<buttonStyle.ButtonVariants>['size'];
  fullWidth?: NonNullable<buttonStyle.ButtonVariants>['fullWidth'];
};

export type LinkVariantTypes = NonNullable<style.LinkStyleVariants>;
interface ITextLinkProps extends Omit<LinkProps, 'to'>, IconOptions {
  href: string;
  children: React.ReactNode;
  linkRef?: React.Ref<HTMLAnchorElement>;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

type TextLinkProps = style.LinkStyleVariants & ITextLinkProps & AsButtonPropsType;

interface IButtonLinkProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, IconOptions {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  linkRef?: React.Ref<HTMLButtonElement>;
}

type ButtonLinkProps = style.LinkStyleVariants & IButtonLinkProps & AsButtonPropsType;

export type LinkComponentProps = TextLinkProps | ButtonLinkProps;

const linkEventHandlers = {
  onMouseDown: (e: React.MouseEvent) => e.preventDefault(),
};

const hasHref = (props: LinkComponentProps): props is TextLinkProps => 'href' in props;

const LinkContent = ({ iconLeft, iconRight, children }: Partial<TextLinkProps | ButtonLinkProps>) => {
  const IconLeft = iconLeft;
  const IconRight = iconRight;

  return (
    <>
      {IconLeft && <IconLeft />}
      {children}
      {IconRight && <IconRight />}
    </>
  );
};

const TextLink = ({ href, linkRef, disabled, size, variant, children, className, ...props }: TextLinkProps) => {
  const linkClasses = style.linkStyles({ size, variant, disabled });
  const buttonClasses = cx([
    buttonStyle.button({
      variant: props.buttonVariant,
      size: props.buttonSize,
      display: 'inline',
      fullWidth: props.fullWidth,
    }),
    style.asButtonLink,
  ]);
  const resolvedClasses = cx((props.asButton && buttonClasses) || linkClasses, className);

  if (disabled) {
    return (
      <button type="button" {...linkEventHandlers} className={resolvedClasses} disabled>
        <LinkContent {...props}>{children}</LinkContent>
      </button>
    );
  }

  if (href.match(/^(https?|mailto|data)/)) {
    return (
      <a
        ref={linkRef}
        href={href}
        target="_blank"
        rel="noreferrer"
        {...linkEventHandlers}
        className={resolvedClasses}
        {...props}
      >
        <LinkContent {...props}>{children}</LinkContent>
      </a>
    );
  }

  return (
    <RouterLink to={href} {...linkEventHandlers} className={resolvedClasses} {...props}>
      <LinkContent {...props}>{children}</LinkContent>
    </RouterLink>
  );
};

const ButtonLink = ({ linkRef, size, variant, children, ...props }: ButtonLinkProps) => {
  const linkClasses = style.linkStyles({ size, variant });
  const buttonClasses = buttonStyle.button({
    variant: props.buttonVariant,
    size: props.buttonSize,
    display: 'inline',
    fullWidth: props.fullWidth,
  });
  const resolvedClasses = (props.asButton && buttonClasses) || linkClasses;

  return (
    <button ref={linkRef} type="button" {...linkEventHandlers} {...props} className={resolvedClasses}>
      <LinkContent {...props}>{children}</LinkContent>
    </button>
  );
};

export const Link = (props: LinkComponentProps) => {
  if (hasHref(props)) {
    return <TextLink {...props} />;
  }
  return <ButtonLink {...props} />;
};
