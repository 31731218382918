import React from 'react';
import cx from 'classnames';
import * as styles from './Timeline.css';

/**
 * This component is based on the Material's UI Timeline component.
 * I stripped it from all the redundant features and apply our customizations according to Account Intel design
 * Figma link: https://www.figma.com/design/GMqGAxcxmByohh8OgKpcbM/V1-Target-Accounts-%26-Account-Detail-Pages?node-id=1453-4289&t=l9j93SKTkr9aGDcF-11
 * Material-UI GitHub: https://github.com/mui/material-ui/tree/next/packages/mui-lab/src/Timeline
 */

type ChildrenProps = { children: React.ReactNode };

type TimelineDotProps = { active?: boolean };
const TimelineDot = ({ active }: TimelineDotProps) => {
  return <span className={cx(styles.dot, { [styles.activeDot]: active })} />;
};

type ConnectorProps = { limitedHeight?: boolean; invisible?: boolean };
const TimelineConnector = ({ invisible, limitedHeight }: ConnectorProps) => {
  return (
    <span
      className={cx(styles.connector, {
        [styles.connectorInvisible]: invisible,
        [styles.connectorLimitedHeight]: limitedHeight,
      })}
    />
  );
};

const BaseTimelineSeparator = ({ children }: ChildrenProps) => {
  return <div className={styles.separator}>{children}</div>;
};

type TimelineSeparatorProps = { active?: boolean; first?: boolean; last?: boolean };
export const TimelineSeparator = ({ active, first, last }: TimelineSeparatorProps) => {
  return (
    <BaseTimelineSeparator>
      <TimelineConnector limitedHeight invisible={first} />
      <TimelineDot active={active} />
      {last ? null : <TimelineConnector />}
    </BaseTimelineSeparator>
  );
};

export const TimelineOppositeContent = ({ children }: ChildrenProps) => {
  return <div className={styles.oppositeContent}>{children}</div>;
};

export const TimelineContent = ({ children }: ChildrenProps) => {
  return <div className={styles.content}>{children}</div>;
};

export const TimelineItem = ({
  children,
  noOppositeContent,
}: {
  children: React.ReactNode;
  noOppositeContent?: boolean;
}) => {
  const oppositeContentState = noOppositeContent ?? false;
  return <li className={cx(styles.item, { [styles.itemOppositeContent]: oppositeContentState })}>{children}</li>;
};

export const TimelineRoot = ({ children, id }: ChildrenProps & { id?: string }) => {
  return (
    <ul className={styles.root} id={id}>
      {children}
    </ul>
  );
};
