import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import React, { MouseEventHandler } from 'react';
import { useId } from '../../utils';
import { Label } from '../Label';
import { Typography } from '../Typography';
import * as style from './RadioOption.css';
import { Tooltip } from '../Tooltip';

export type RadioOptionVariantTypes = NonNullable<style.Variants>;

export type RadioOptionProps = RadioGroupPrimitive.RadioGroupItemProps &
  style.Variants & {
    label?: React.ReactNode | string;
    description?: React.ReactNode | string;
    indicatorSide: 'left' | 'right';
    onHover?: MouseEventHandler<HTMLDivElement>;
    tooltipContent?: React.ReactNode;
    children?: React.ReactNode;
  };

export const RadioOption = ({
  variant,
  label,
  description,
  disabled,
  orientation,
  indicatorSide,
  tooltipContent,
  children,
  ...props
}: RadioOptionProps) => {
  return tooltipContent ? (
    <Tooltip content={tooltipContent} side="bottom" asChild>
      <div
        className={style.option({ variant, orientation })}
        data-state={props.checked ? 'checked' : 'unchecked'}
        data-disabled={disabled || undefined}
      >
        <RadioGroupItem
          variant={variant}
          label={label}
          description={description}
          disabled={disabled}
          orientation={orientation}
          indicatorSide={indicatorSide}
          {...props}
        />
        {props.checked && children && <div className={style.optionChildren}>{children}</div>}
      </div>
    </Tooltip>
  ) : (
    <div
      className={style.option({ variant, orientation })}
      data-state={props.checked ? 'checked' : 'unchecked'}
      data-disabled={disabled || undefined}
    >
      <RadioGroupItem
        variant={variant}
        label={label}
        description={description}
        disabled={disabled}
        orientation={orientation}
        indicatorSide={indicatorSide}
        {...props}
      />
      {props.checked && children && <div className={style.optionChildren}>{children}</div>}
    </div>
  );
};

const RadioGroupItem = ({
  variant,
  label,
  description,
  disabled,
  orientation,
  indicatorSide,
  ...props
}: RadioOptionProps) => {
  const id = useId();

  return (
    <Label aria-disabled={disabled} htmlFor={id}>
      <div className={style.inner({ variant, orientation })}>
        {indicatorSide === 'right' && <Content label={label} description={description} />}
        <RadioGroupPrimitive.Item
          {...props}
          disabled={disabled}
          id={id}
          className={style.item({ variant: variant ?? 'minimal' })}
        >
          <RadioGroupPrimitive.Indicator className={style.indicator} />
        </RadioGroupPrimitive.Item>
        {indicatorSide === 'left' && <Content label={label} description={description} />}
      </div>
    </Label>
  );
};

type ContentProps = Pick<RadioOptionProps, 'label' | 'description'>;

function Content({ label, description }: ContentProps) {
  return (
    <div className={style.content}>
      {/* label font is inherited from Label */}
      <div>{label}</div>
      {description && (
        <Typography font="caption1" color="secondary">
          {description}
        </Typography>
      )}
    </div>
  );
}
