import React from 'react';
import cx from 'classnames';
import * as PrimitiveDialog from '@radix-ui/react-dialog';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import * as styles from './Flyout.css';
import { IconButton } from '../Button';

export type FlyoutProps = PrimitiveDialog.DialogProps;

export const FlyoutTrigger = ({ children, ...props }: PrimitiveDialog.DialogTriggerProps): JSX.Element => (
  <PrimitiveDialog.Trigger {...props} asChild>
    {children}
  </PrimitiveDialog.Trigger>
);

export const FlyoutHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={styles.header}>
      {children}
      <PrimitiveDialog.Close asChild>
        <IconButton variant="secondary" icon={Icons.Close} label="close" aria-label="close" />
      </PrimitiveDialog.Close>
    </div>
  );
};

export const FlyoutHeaderActions = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.headerActions}>{children}</div>;
};

export const FlyoutBody = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.body}>{children}</div>;
};

type Props = {
  children?: React.ReactNode;
  size?: keyof typeof styles.flyoutContentBaseSizes;
};

export const FlyoutContainer = ({ children, size = 'medium' }: Props) => {
  return (
    <PrimitiveDialog.Portal>
      <PrimitiveDialog.Overlay data-testid="overlay-test" className={styles.flyoutOverlay} />;
      <PrimitiveDialog.Content className={cx(styles.flyoutContentBase, styles.flyoutContentBaseSizes[size])}>
        {children}
      </PrimitiveDialog.Content>
    </PrimitiveDialog.Portal>
  );
};

const FlyoutRoot = ({ children, ...props }: PrimitiveDialog.DialogProps): JSX.Element => (
  <PrimitiveDialog.Root {...props}>{children}</PrimitiveDialog.Root>
);

export const Flyout = Object.assign(FlyoutRoot, {
  Root: FlyoutRoot,
  Trigger: FlyoutTrigger,
  Container: FlyoutContainer,
  Header: FlyoutHeader,
  HeaderActions: FlyoutHeaderActions,
  Body: FlyoutBody,
});
