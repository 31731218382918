import React, { ReactNode, useEffect } from 'react';
import { badge, link, previewContainer, previewFavicon, previewHostname, previewImage } from './CitationBadge.css';
import { Tooltip } from '../Tooltip';
import { Flex } from '../Flex';
import { Box } from '../Box';
import { Whoosher } from '../Loaders/Whoosher';
import { Spinner } from '../Loaders/Spinner';
import { ImageWithFallback } from '../ImageWithFallback';
import FallbackImage from '../../icons/iconic/svg/globe.svg';
import { Typography } from './Typography';

// not sensitive - accessible client side anyways
const LINKPREVIEW_API_KEY = 'adac31f19333d5f2fac94e69aa3f1d52';

type LinkMetadata = {
  title: string;
  description: string;
  image: string;
  favicon: string;
};

const localCache: Record<string, Promise<LinkMetadata | undefined> | undefined> = {};

// exposed for testing
export function formatHostname(url: string) {
  try {
    const withProtocol = /^[a-z]*:?\/\//.test(url) ? url : `https://${url}`;
    return new URL(withProtocol).hostname;
  } catch {
    return url;
  }
}

const LinkPreview = ({ href }: { href: string }) => {
  const [loading, setLoading] = React.useState(true);
  const [meta, setMeta] = React.useState<LinkMetadata | undefined>();

  useEffect(() => {
    if (localCache[href]) {
      localCache[href].then((data) => {
        setMeta(data);
        setLoading(false);
      });
      return;
    }

    localCache[href] = (async () => {
      try {
        const res = await fetch(`https://api.linkpreview.net/?q=${href}&fields=icon`, {
          headers: {
            'X-Linkpreview-Api-Key': LINKPREVIEW_API_KEY,
          },
        });
        const json = await res.json();
        const siteMeta = {
          title: json.title,
          description: json.description,
          image: json.image,
          favicon: json.icon,
        };
        setMeta(siteMeta);
        return siteMeta;
      } catch {
        setMeta(undefined);
      } finally {
        setLoading(false);
      }

      return undefined;
    })();
  }, [href]);

  return (
    <a className={link} href={href} target="_blank" rel="noreferrer">
      <Flex.Container className={previewContainer} flexDirection="column">
        {loading ? (
          <Box className={previewImage}>
            <Typography color="highlight">
              <Spinner />
            </Typography>
          </Box>
        ) : meta?.image ? (
          <img className={previewImage} src={meta.image} alt="Preview" />
        ) : null}
        <Flex.Container margin="6u" flexDirection="column" gap="4u">
          {loading && <Whoosher />}
          {meta?.title && (
            <Typography color="default" fontWeight="semibold">
              {meta.title}
            </Typography>
          )}
          <Flex.Container gap="2u" alignItems="center">
            <ImageWithFallback className={previewFavicon} src={meta?.favicon ?? ''} fallbackSrc={FallbackImage} />
            <Typography className={previewHostname} color="default">
              {formatHostname(href)}
            </Typography>
          </Flex.Container>
        </Flex.Container>
      </Flex.Container>
    </a>
  );
};

type CitationBadgeProps = {
  children: ReactNode;
  href: string;
};

export const CitationBadge = ({ children, href }: CitationBadgeProps) => {
  return (
    <Tooltip delayDuration={200} asChild padding="none" content={<LinkPreview href={href} />}>
      <a className={link} href={href} target="_blank" rel="noreferrer">
        <span className={badge}>{children}</span>
      </a>
    </Tooltip>
  );
};
