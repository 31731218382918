/* eslint-disable react/function-component-definition */
import React, { useMemo } from 'react';
import cx from 'classnames';
import ReactSelectComponent, * as ReactSelect from 'react-select5';
import Creatable, { CreatableProps } from 'react-select5/creatable';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import { Checkbox } from '../Checkbox/Checkbox';
import { Label } from '../Label';
import { ExpandIndicator } from '../Select/ExpandIndicator';
import * as style from './MultiSelect.css';
import { SelectInput, SelectPlaceholder } from '../Select';

declare module 'react-select5/base' {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  export interface Props<
    OptionType,
    IsMulti extends boolean,
    Group extends ReactSelect.GroupBase<OptionType>,
    GroupType extends ReactSelect.GroupBase<OptionType>,
  > {
    footer?: React.ReactNode;
  }
}

export type MultiSelectProps<OptionType> = Omit<
  ReactSelect.Props<OptionType, true>,
  'isMulti' | 'unstyled' | 'hideSelectedOptions' | 'components' | 'classNames'
> & {
  footer?: React.ReactNode;
  CustomOptionComponent?: React.ComponentType<ReactSelect.OptionProps<OptionType, true>>;
};

export const Control = <T,>(props: ReactSelect.ControlProps<T, true>) => {
  const handlePaste = (e: React.ClipboardEvent) => {
    if (!props.selectProps.onPaste) {
      return;
    }

    e.preventDefault();

    const selections = new Set<string>();
    e.clipboardData
      .getData('text/plain')
      .trim()
      .split(/\r\n|\n\r|\n|\r|\t/)
      .forEach((v) => selections.add(v));

    props.selectProps.onPaste(Array.from(selections));
  };

  return (
    <SelectInput
      {...props.innerProps}
      {...props}
      onPaste={handlePaste}
      ref={props.innerRef as React.Ref<HTMLDivElement>}
      showIndicator={false}
      multi={props.isMulti}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CLASS_NAMES_CONFIG: ReactSelect.ClassNamesConfig<any, true> = {
  control: () => style.control,
  placeholder: () => style.placeholder,
  valueContainer: () => style.valueContainer,
  multiValue: () => style.multiValue,
  multiValueLabel: () => style.multiValueLabel,
  multiValueRemove: () => style.multiValueRemove,
  clearIndicator: () => style.clearIndicator,
  indicatorSeparator: () => style.indicatorSeparator,
  menuPortal: () => style.menuPortal,
  dropdownIndicator: () => style.dropdownIndicator,
  menu: () => style.menu,
  noOptionsMessage: () => style.noOptionsOrLoadingMessage,
  loadingMessage: () => style.noOptionsOrLoadingMessage,
};

const ReactSelectMenu = ReactSelect.components.Menu;

export const FooterText = ({
  children,
  className,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cx(style.footer, className)} {...props}>
      <Label disabled>{children}</Label>
    </div>
  );
};

export function FooterMenu<OptionType>({ children, selectProps, ...props }: ReactSelect.MenuProps<OptionType, true>) {
  return (
    <ReactSelectMenu<OptionType, true, ReactSelect.GroupBase<OptionType>> selectProps={selectProps} {...props}>
      {children}
      {selectProps.footer && selectProps.footer}
    </ReactSelectMenu>
  );
}

export function MultiSelect<OptionType>({
  CustomOptionComponent,
  controlProps,
  ...props
}: MultiSelectProps<OptionType>) {
  return (
    <div aria-expanded={props.menuIsOpen}>
      <ReactSelectComponent<OptionType, true>
        {...props}
        aria-label="Select..."
        menuShouldScrollIntoView
        menuPortalTarget={document.body}
        isMulti
        unstyled
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        components={{
          Control: useMemo(
            () => (baseProps: ReactSelect.ControlProps) => <Control {...baseProps} {...controlProps} />,
            [controlProps],
          ),
          Placeholder: SelectPlaceholder,
          DropdownIndicator,
          ClearIndicator,
          Option: CustomOptionComponent ?? Option,
          Menu: FooterMenu,
        }}
        classNames={CLASS_NAMES_CONFIG}
      />
    </div>
  );
}

export type CreatableMultiSelectProps<OptionType, IsMulti extends boolean = true> = CreatableProps<
  OptionType,
  IsMulti,
  ReactSelect.GroupBase<OptionType>
> & {
  CustomOptionComponent?: React.ComponentType<ReactSelect.OptionProps<OptionType, true>>;
};

export function CreatableMultiSelect<OptionType>({
  CustomOptionComponent,
  controlProps,
  ...props
}: CreatableMultiSelectProps<OptionType>) {
  return (
    <div aria-expanded={props.menuIsOpen}>
      <Creatable<OptionType, true>
        {...props}
        aria-label="Select..."
        menuShouldScrollIntoView
        isMulti
        unstyled
        closeMenuOnSelect={false}
        components={{
          Control: useMemo(
            () => (baseProps: ReactSelect.ControlProps) => <Control {...baseProps} {...controlProps} />,
            [controlProps],
          ),
          Placeholder: SelectPlaceholder,
          DropdownIndicator,
          ClearIndicator,
          Option: CustomOptionComponent ?? Option,
          Menu: FooterMenu,
        }}
        classNames={CLASS_NAMES_CONFIG}
      />
    </div>
  );
}

const DefaultClearIndicator = ReactSelect.components.ClearIndicator;

export function ClearIndicator<OptionType>(props: ReactSelect.ClearIndicatorProps<OptionType, true>) {
  return (
    <DefaultClearIndicator {...props}>
      <Icons.Close />
    </DefaultClearIndicator>
  );
}

const DefaultDropdownIndicator = ReactSelect.components.DropdownIndicator;

export function DropdownIndicator<OptionType>(props: ReactSelect.DropdownIndicatorProps<OptionType, true>) {
  return (
    <DefaultDropdownIndicator {...props}>
      <ExpandIndicator aria-expanded={props.selectProps.menuIsOpen} />
    </DefaultDropdownIndicator>
  );
}

export function Option<OptionType>({
  innerProps,
  children,
  clearValue,
  selectOption,
  isSelected,
  data,
  isFocused,
  isDisabled,
}: ReactSelect.OptionProps<OptionType, true>) {
  return (
    <div
      className={style.option}
      data-highlighted={isFocused || undefined}
      data-disabled={isDisabled || undefined}
      data-state={(isSelected && 'checked') || undefined}
      {...innerProps}
    >
      <Checkbox
        checked={isSelected}
        onCheckedChange={(nextChecked) => (nextChecked ? selectOption(data) : clearValue())}
      />
      <Label fullWidth>{children}</Label>
    </div>
  );
}
