/* eslint-disable react/function-component-definition */
import React, { useMemo } from 'react';
import * as ReactSelect from 'react-select5';
import ReactSelectComponent, { CreatableProps } from 'react-select5/creatable';
import _ from 'lodash';
import { Label } from '../Label';
import { ExpandIndicator } from '../Select/ExpandIndicator';
import * as style from './MultiSelect.css';
import { SelectInput, SelectInputProps, SelectPlaceholder } from '../Select';
import { Spinner } from '../Loaders';
import { Checkbox } from '../Checkbox';

export type TagType = {
  name: string;
  label: string;
  value: string;
};

export type TagsProps<OptionType> = Omit<
  CreatableProps<OptionType, true, ReactSelect.GroupBase<OptionType>>,
  'isMulti' | 'unstyled' | 'hideSelectedOptions' | 'components' | 'classNames'
> & {
  onAdd?: (tag: OptionType) => void;
  onRemove?: (tag: OptionType) => void;
  placeholder?: string;
  controlProps?: Partial<SelectInputProps>;
};

export interface OptionTypeBase {
  __isNew__?: boolean;
  value: string;
}

const Control = <T,>(props: ReactSelect.ControlProps<T, true> & SelectInputProps) => {
  return (
    <SelectInput
      {...props.innerProps}
      {...props}
      ref={props.innerRef as React.Ref<HTMLDivElement>}
      showIndicator={false}
      multi={props.isMulti}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CLASS_NAMES_CONFIG: ReactSelect.ClassNamesConfig<any, true> = {
  control: () => style.control,
  placeholder: () => style.placeholder,
  valueContainer: () => style.valueContainer,
  multiValue: () => style.multiValue,
  multiValueLabel: () => style.multiValueLabel,
  multiValueRemove: () => style.multiValueRemove,
  clearIndicator: () => style.clearIndicator,
  dropdownIndicator: () => style.dropdownIndicator,
  menu: () => style.menu,
  noOptionsMessage: () => style.noOptionsOrLoadingMessage,
  loadingMessage: () => style.noOptionsOrLoadingMessage,
};

export function Tags<OptionType extends OptionTypeBase = TagType>({ controlProps, ...props }: TagsProps<OptionType>) {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = async (
    nextValue: ReactSelect.MultiValue<OptionType>,
    actionMeta: ReactSelect.ActionMeta<OptionType>,
  ) => {
    props.onChange?.(nextValue, actionMeta);

    if (!props.onAdd && !props.onRemove) {
      return;
    }

    const value = (props.value as ReadonlyArray<OptionType>) || [];
    const diff = _.xorWith<OptionType>(value, nextValue, _.isEqual);

    if (diff.length === 0) {
      return;
    }

    setIsLoading(true);

    await Promise.all(
      diff.map(async (option) => {
        if (value.includes(option)) {
          await props.onRemove?.(option);
        }
        if (nextValue.includes(option)) {
          option.__isNew__ = false;
          await props.onAdd?.(option);
        }
      }),
    );

    setIsLoading(false);
  };

  return (
    <div aria-expanded={props.menuIsOpen}>
      <ReactSelectComponent<OptionType, true>
        placeholder={props.placeholder ?? 'Select tags'}
        aria-label={props.placeholder ?? 'Select tags'}
        {...props}
        onChange={handleChange}
        menuShouldScrollIntoView
        isMulti
        unstyled
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isClearable={false}
        isLoading={isLoading}
        components={{
          Control: useMemo(
            () => (baseProps: ReactSelect.ControlProps) => <Control {...baseProps} {...controlProps} />,
            [controlProps],
          ),
          Placeholder: SelectPlaceholder,
          DropdownIndicator,
          Option,
          NoOptionsMessage,
          LoadingIndicator,
        }}
        classNames={CLASS_NAMES_CONFIG}
      />
    </div>
  );
}

function Option<OptionType>({
  innerProps,
  children,
  isSelected,
  isFocused,
}: ReactSelect.OptionProps<OptionType, true>) {
  return (
    <div
      className={style.option}
      data-highlighted={isFocused || undefined}
      data-state={(isSelected && 'checked') || undefined}
      {...innerProps}
    >
      <Checkbox checked={isSelected} onCheckedChange={() => null} />
      <Label>{children}</Label>
    </div>
  );
}

const DefaultDropdownIndicator = ReactSelect.components.DropdownIndicator;

function DropdownIndicator<OptionType>(props: ReactSelect.DropdownIndicatorProps<OptionType, true>) {
  return (
    <DefaultDropdownIndicator {...props}>
      <ExpandIndicator aria-expanded={props.selectProps.menuIsOpen} />
    </DefaultDropdownIndicator>
  );
}

function NoOptionsMessage() {
  return (
    <div className={style.noOptionsOrLoadingMessage}>
      <Label>Type to create a tag</Label>
    </div>
  );
}

function LoadingIndicator() {
  return <Spinner />;
}
