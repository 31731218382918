import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import { DropdownMenuTriggerProps } from '@radix-ui/react-dropdown-menu';
import { ButtonVariantEnum, IconButton } from '../Button/Button';
import { ArrowKeysT, SelectSearchContainer } from './SearchContainer';
import { SelectInput } from '../Select/SelectInput';
import * as Dropdown from './Dropdown';
import { ItemLayout } from './Dropdown.css';
import * as style from './ComboBox.css';
import * as selectStyle from './SingleSelect.css';
import { SingleSelectOption } from './SingleSelect';
import { TextInput, TextInputValue } from '../TextInput/TextInput';

import { Typography } from '../Typography/Typography';
import { ToggleGroup, ToggleGroupItem, ToggleGroupProps } from '../ToggleGroup';
import { Flex } from '../Flex';

export type ComboBoxOption = SingleSelectOption;

export type OptionRenderer = (option: ComboBoxOption) => React.ReactNode;

export interface ComboBoxProps extends Dropdown.ContentProps, Pick<Dropdown.RootProps, 'onOpenChange'> {
  options: ComboBoxOption[];
  value: string | null | undefined;
  onValueChange: (newValue: string) => void;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  itemLayout?: ItemLayout;
  searchBarProps?: React.ComponentProps<typeof TextInput>;
  slider?: boolean;
  optionRenderer?: OptionRenderer;
  optionVariant?: 'default' | 'minimal';
  triggerProps?: DropdownMenuTriggerProps;
  asChild?: boolean;
  toggleGroupOptions?: { label: string; value: string }[];
  toggleGroupProps?: ToggleGroupProps;
  buttonComponent?: React.ReactNode;
  inputContainerStyles?: React.CSSProperties;
}

const searchTermMatcher =
  <Option extends SingleSelectOption>(searchTerm: string) =>
  (item: Option) => {
    if (item.label && item.label.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) {
      return true;
    }
    if (item.description && item.description.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) {
      return true;
    }
    return item.value.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
  };

export const useSearchArrowNavigation = () => {
  const firstItemRef = useRef<HTMLDivElement | null>(null);
  const lastItemRef = useRef<HTMLDivElement | null>(null);

  const onSearchArrowNavigation = useCallback(
    (key: ArrowKeysT) => {
      if (key === 'ArrowDown') {
        firstItemRef?.current?.focus();
      }
      if (key === 'ArrowUp') {
        lastItemRef?.current?.focus();
      }
    },
    [firstItemRef, lastItemRef],
  );
  return { firstItemRef, lastItemRef, onSearchArrowNavigation };
};

export const useSearching = <Option extends SingleSelectOption>({
  searchBarProps = {},
  options,
  onOpenChange,
}: Pick<ComboBoxProps, 'searchBarProps' | 'onOpenChange'> & { options: Option[] }) => {
  const isControlled = searchBarProps.onValueChange !== undefined;
  const onSearchValueChange = useRef(searchBarProps.onValueChange).current;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [displayedOptions, setDisplayedOptions] = useState<Option[]>([]);

  const clearState = useCallback(
    (selectOpen: boolean) => {
      if (!selectOpen) {
        setSearchTerm('');
        setDisplayedOptions(options);
      }
      onOpenChange?.(selectOpen);
    },
    [setDisplayedOptions, setSearchTerm, options, onOpenChange],
  );

  const handleSearch = useCallback(
    (v: TextInputValue) => {
      if (!isControlled) {
        setSearchTerm(v?.toString() || '');
      }
      onSearchValueChange?.(v);
    },
    [setSearchTerm, onSearchValueChange, isControlled],
  );

  useEffect(() => {
    if (!searchTerm || isControlled) {
      setDisplayedOptions(options);
    } else {
      setDisplayedOptions(() => {
        const matchSearchTerm = searchTermMatcher<Option>(searchTerm);
        return options.filter(matchSearchTerm);
      });
    }
  }, [searchTerm, setDisplayedOptions, options, isControlled]);
  return {
    searchTerm,
    displayedOptions,
    handleSearch,
    clearState,
  };
};

export const ComboBox = ({
  toggleGroupOptions,
  toggleGroupProps,
  children,
  asChild = !children,
  options,
  value: selectedValue,
  onValueChange,
  label,
  placeholder,
  disabled,
  itemLayout = 'column',
  searchBarProps = {},
  onOpenChange,
  optionRenderer,
  slider = false,
  optionVariant = 'default',
  triggerProps,
  buttonComponent,
  inputContainerStyles,
  ...contentProps
}: ComboBoxProps) => {
  const { clearState, handleSearch, displayedOptions } = useSearching({ searchBarProps, options, onOpenChange });
  const { firstItemRef, lastItemRef, onSearchArrowNavigation } = useSearchArrowNavigation();
  const [open, setOpen] = useState(false);
  const selectedOption = children == null ? options.find((o) => o.value === selectedValue) : null;
  const currentIndex = options.findIndex((o) => o.value === selectedValue);

  const sliderNextValue = useCallback(() => {
    if (options.length) {
      const newValue = options[(currentIndex + 1) % options.length]?.value;
      if (newValue) onValueChange(newValue);
    }
  }, [options, selectedValue]);

  const sliderPreviousValue = useCallback(() => {
    if (options.length) {
      const newValue = options[(currentIndex - 1 + options.length) % options.length]?.value;
      if (newValue) onValueChange(newValue);
    }
  }, [options, selectedValue]);

  return (
    <Dropdown.Root
      onOpenChange={(newOpen) => {
        setOpen(newOpen);
        clearState(newOpen);
      }}
      open={open}
    >
      <div className={style.inputContainer} style={inputContainerStyles}>
        {slider && (
          <IconButton
            variant={ButtonVariantEnum.secondary}
            className={style.sliderButtonLeft}
            label="Previous"
            icon={Icons.ChevronLeft}
            onClick={sliderPreviousValue}
          />
        )}
        <Dropdown.Trigger asChild={asChild} disabled={disabled} {...triggerProps}>
          {children ?? (
            <SelectInput label={label} disabled={disabled} editable={false} showIndicator={!slider}>
              {selectedOption ? selectedOption.label ?? selectedOption.value : placeholder}
            </SelectInput>
          )}
        </Dropdown.Trigger>
        {slider && (
          <IconButton
            variant={ButtonVariantEnum.secondary}
            className={style.sliderButtonRight}
            label="Next"
            icon={Icons.ChevronRight}
            onClick={sliderNextValue}
          />
        )}
      </div>

      <Dropdown.Content
        align="start"
        side="bottom"
        {...contentProps}
        avoidCollisions={false}
        collisionPadding={40}
        matchTriggerWidth
      >
        <SelectSearchContainer
          onSearchNavigation={onSearchArrowNavigation}
          onValueChange={handleSearch}
          fullWidth
          {...searchBarProps}
        >
          {!displayedOptions.length && (
            <Typography font="body1" className={style.noResults}>
              No results
            </Typography>
          )}
        </SelectSearchContainer>
        {toggleGroupOptions && toggleGroupProps && (
          <Flex.Container justifyContent="center">
            <ToggleGroup {...toggleGroupProps}>
              {toggleGroupOptions.map((option) => (
                <ToggleGroupItem key={option.value} value={option.value}>
                  {option.label}
                </ToggleGroupItem>
              ))}
            </ToggleGroup>
          </Flex.Container>
        )}
        <Dropdown.RadioGroup value={selectedValue ?? undefined} onValueChange={onValueChange}>
          {displayedOptions.map((option, idx) => {
            let itemRef;
            if (idx === 0) {
              itemRef = firstItemRef;
            } else if (idx === displayedOptions.length - 1) {
              itemRef = lastItemRef;
            }

            return (
              <Dropdown.RadioItem
                key={option.value}
                value={option.value}
                layout={itemLayout}
                ref={itemRef}
                colorTheme={optionVariant}
                className={style.radioItem}
              >
                {optionRenderer ? (
                  optionRenderer(option)
                ) : (
                  <React.Fragment key={option.value}>
                    <div>{option.label ?? option.value}</div>
                    {!!option.description && <div className={selectStyle.description}>{option.description}</div>}
                  </React.Fragment>
                )}
              </Dropdown.RadioItem>
            );
          })}
        </Dropdown.RadioGroup>
        {buttonComponent && (
          // need to close the dropdown do it doesn't fight with modal
          <div className={style.button} onClick={() => setOpen(false)}>
            {buttonComponent}
          </div>
        )}
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
